<template>
  <div>
    <!-- MOBILE -->
    <v-row
      v-if="$vuetify.breakpoint.mobile"
      no-gutters
    >
      <v-col cols="6">
        {{ $t('cases|progress') }}
      </v-col>
      <v-col cols="6">
        <v-slider
          v-model="sliderValue"
          step="1"
          thumb-label
          color="primary"
          track-fill-color="success"
          @end="$emit('update-progress', sliderValue)"
        >
          <template v-slot:thumb-label="{ value }">
            {{ value + '%' }}
          </template>
        </v-slider>
      </v-col>
    </v-row>
    <!-- DESKTOP -->
    <div v-else>
      <v-hover v-slot="{ hover }">
        <v-row
          v-if="account && account.accountType !== 'lawyer'"
          align="center"
          no-gutters
        >
          <v-col :cols="colsTitle">
            <span>{{ $t('cases|progress') }}:</span>
          </v-col>
          <v-col :cols="colsProgressBar">
            <v-progress-linear
              :value="currentCase.progress"
              color="success"
              rounded
              height="15"
              class="mt-1"
            >
              <template v-slot:default="{ value }">
                <strong
                  class="pt-1"
                  style="font-size: 13px;"
                >
                  {{ value.toString() }}%
                </strong>
              </template>
            </v-progress-linear>
            <!-- <strong>{{ Math.ceil(caseProgress) }}%</strong> -->
          </v-col>
        </v-row>
        <v-row
          v-else
          align="center"
          no-gutters
          class="mt-1"
        >
          <v-col :cols="colsTitle">
            <span>{{ $t('cases|progress') }}:</span>
          </v-col>
          <v-col :cols="colsProgressBar">
            <v-slider
              v-if="hover"
              v-model="sliderValue"
              step="1"
              thumb-label
              color="primary"
              track-fill-color="success"
              class="mt-n1 mb-n8"
              @end="$emit('update-progress', sliderValue)"
            >
              <template v-slot:thumb-label="{ value }">
                {{ value + '%' }}
              </template>
            </v-slider>
            <v-progress-linear
              v-else
              v-model="currentCase.progress"
              rounded
              color="success"
              height="15"
              class="mt-1"
            >
              <template v-slot:default="{ value }">
                <strong
                  style="font-size: 13px;"
                >
                  {{ value.toString() }}%
                </strong>
              </template>
            </v-progress-linear>
          </v-col>
          <v-col cols="1">
            <v-icon
              v-if="account.accountType === 'lawyer'"
              color="primary"
              class="ml-2"
              @click="$emit('open-info-progress-dialog')"
            >
              mdi-information-variant
            </v-icon>
          </v-col>
        </v-row>
      </v-hover>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    account: {
      type: Object,
      default: null
    },
    status: {
      type: String,
      default: null
    },
    colsTitle: {
      type: [String, Number],
      default: 4
    },
    colsProgressBar: {
      type: [String, Number],
      default: 8
    },
    currentCase: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      sliderValue: 0
    }
  },
  computed: {
    caseProgress () {
      if (this.status === 'inprogress') return 66
      if (this.status === 'done') return 100
      return 33
    }
  },
  watch: {
    currentCase: {
      handler (value) {
        if (this.currentCase && Object.keys(this.currentCase).length && this.currentCase.progress) {
          this.sliderValue = this.currentCase.progress
        }
      },
      immediate: true
    }
  }
  // methods: {
  //   emitEvent (value) {
  //     this.$emit('update-progress', value)
  //   }
  // }
}
</script>
